<template>
  <div>
    <div class="form-box">
      <div v-if="!isChart" class="select-chart" @click="addChart">
        <div class="add-chart">
          <i class="iconfont icon-zengjia"></i>    
          <span>选择图表</span>
        </div>
      </div>
      <!-- 实时图表realtime -->
      <div v-if="isChart=='bar-realtime-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">实时统计-横向单柱状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox1/>
        </div>
      </div>
      <div v-if="isChart=='bar-realtime-2'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">实时统计-纵向单柱状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox15/>
        </div>
      </div>
      <div v-if="isChart=='distribution-realtime-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">实时统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox7/>
        </div>
      </div>
      <div v-if="isChart=='distribution-realtime-2'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">实时统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox8/>
        </div>
      </div>

      <!-- 累计统计图表total -->
      <div v-if="isChart=='bar-total-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">累计统计-纵向单柱状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox2/>
        </div>
      </div>
      <div v-if="isChart=='pie-chart-total-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">累计统计-饼状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox12/>
        </div>
      </div>
      <!-- <div v-if="isChart=='pie2-total'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">累计统计-饼状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox13/>
        </div>
      </div>
      <div v-if="isChart=='pie3-total'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">累计统计-饼状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox14/>
        </div>
      </div> -->

      <!-- 趋势图表trend -->
      <div v-if="isChart=='distribution-trend-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">趋势统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox6/>
        </div>
      </div>
      <div v-if="isChart=='distribution-trend-2'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">趋势统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox8/>
        </div>
      </div>
      
      <!-- 对比图表contrast -->
      <div v-if="isChart=='bar-contrast-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-横向双状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox3/>
        </div>
      </div>
      <!-- <div v-if="isChart=='bar-contrast-back'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-纵向柱状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox4/>
        </div>
      </div> -->
      <div v-if="isChart=='bar-contrast-2'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-纵向柱状图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox5/>
        </div>
      </div>
      <!-- <div v-if="isChart=='distribution-contrast-back'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox9/>
        </div>
      </div>
      <div v-if="isChart=='distribution-contrast-back'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox10/>
        </div>
      </div> -->
      <div v-if="isChart=='distribution-contrast-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">对比统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox11/>
        </div>
      </div>

      <!-- 合并趋势统计distribution-merge-trend-1 -->
      <div v-if="isChart=='distribution-merge-trend-1'">
        <div class="chart-flex">
          <div class="chart-title">
            <span class="l-title">合并趋势统计-折线图</span>
            <el-button type="text" style="color:#ff6b6b;" class="r-btn" @click="delChart()">删除</el-button>
          </div>
          <legendBox16/>
        </div>
      </div>
    </div>
    <!-- 选择图表 -->
    <el-drawer
      title="选择图表"
      :with-header="false"
      :visible.sync="drawerDevice"
      direction="rtl"
      size="500px">
      <div class="drawer-title">选择图表</div>
      <div class="drawer-content table-tree">
        <div class="drawer-head">
          <div class="drawer-name">图形类别</div>
          <el-select v-model="selectedShapes" size="small" clearable placeholder="选择筛选图形类别" style="width:100%;">
            <el-option
              v-for="item in shapeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="chart-size">
          <!-- 实时图表realtime -->
          <div style="width:100%;" v-if="selectedShapes=='realtime'">
            <div class="chart-flex" style="width:100%;"> 
              <div class="chart-title">
                <div>
                  <span class="l-title">实时统计-横向单柱状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-realtime-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-realtime-1')">选择</el-button>
              </div>
              <legendBox1/>
            </div>
            <div class="chart-flex" style="width:100%;"> 
              <div class="chart-title">
                <div>
                  <span class="l-title">实时统计-纵向单柱状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-realtime-2'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-realtime-2')">选择</el-button>
              </div>
              <legendBox15/>
            </div>
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">实时统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-realtime-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-realtime-1')">选择</el-button>
              </div>
              <legendBox7/>
            </div>
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">实时统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-realtime-2'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-realtime-2')">选择</el-button>
              </div>
              <legendBox8/>
            </div>
          </div>

          <!-- 累计统计图表total -->
          <div style="width:100%;" v-else-if="selectedShapes=='total'">
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">累计统计-纵向单柱状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-total-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-total-1')">选择</el-button>
              </div>
              <legendBox2/>
            </div>
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">累计统计-饼状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='pie-chart-total-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('pie-chart-total-1')">选择</el-button>
              </div>
              <legendBox12/>
            </div>
            <!-- <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">累计统计-饼状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='pie2-total'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('pie2-total')">选择</el-button>
              </div>
              <legendBox13/>
            </div>
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">累计统计-饼状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='pie3-total'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('pie3-total')">选择</el-button>
              </div>
              <legendBox14/>
            </div> -->
          </div>

          <!-- 趋势图表trend -->
          <div style="width:100%;" v-else-if="selectedShapes=='trend'">
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">趋势统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-trend-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-trend-1')">选择</el-button>
              </div>
              <legendBox6/>
            </div>
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">趋势统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-trend-2'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-trend-2')">选择</el-button>
              </div>
              <legendBox8/>
            </div>
          </div>

          <!-- 对比图表contrast -->
          <div style="width:100%;" v-else-if="selectedShapes=='contrast'">
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-横向双状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-contrast-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-contrast-1')">选择</el-button>
              </div>
              <legendBox3/>
            </div>
            <!-- <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-纵向柱状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-contrast-back'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-contrast-back')">选择</el-button>
              </div>
              <legendBox4/>
            </div> -->
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-纵向柱状图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='bar-contrast-2'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('bar-contrast-2')">选择</el-button>
              </div>
              <legendBox5/>
            </div>
            <!-- <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-contrast-back'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-contrast-back')">选择</el-button>
              </div>
              <legendBox9/>
            </div> -->
            <!-- <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-contrast-back'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-contrast-back')">选择</el-button>
              </div>
              <legendBox10/>
            </div> -->
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">对比统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-contrast-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-contrast-1')">选择</el-button>
              </div>
              <legendBox11/>
            </div>
          </div>
          <div style="width:100%;" v-else-if="selectedShapes=='merge-trend'">
            <div class="chart-flex" style="width:100%;">
              <div class="chart-title">
                <div>
                  <span class="l-title">合并趋势统计-折线图</span>
                  <el-tag effect="dark" type="success" size="mini" v-if="tempChart=='distribution-merge-trend-1'">当前选择</el-tag>
                </div>
                <el-button type="text" class="r-btn" @click="selectChart('distribution-merge-trend-1')">选择</el-button>
              </div>
              <legendBox16/>
            </div>
          </div>
          <div v-else class="chart-empty">暂无数据</div>
        </div>
      </div>
      <div class="drawer-bottom">
        <el-button size="small" type="primary" @click="drawerDevice=false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="drawerDeviceOK"
          class="drawer-bottom-ok">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import legendBox1 from './legendBox1.vue'
import legendBox2 from './legendBox2.vue'
import legendBox3 from './legendBox3.vue'
import legendBox4 from './legendBox4.vue'
import legendBox5 from './legendBox5.vue'
import legendBox6 from './legendBox6.vue'
import legendBox7 from './legendBox7.vue'
import legendBox8 from './legendBox8.vue'
import legendBox9 from './legendBox9.vue'
import legendBox10 from './legendBox10.vue'
import legendBox11 from './legendBox11.vue'
import legendBox12 from './legendBox12.vue'
import legendBox13 from './legendBox13.vue'
import legendBox14 from './legendBox14.vue'
import legendBox15 from './legendBox15.vue'
import legendBox16 from './legendBox16.vue'
export default {
  components: {
    legendBox1,
    legendBox2,
    legendBox3,
    legendBox4,
    legendBox5,
    legendBox6,
    legendBox7,
    legendBox8,
    legendBox9,
    legendBox10,
    legendBox11,
    legendBox12,
    legendBox13,
    legendBox14,
    legendBox15,
    legendBox16,
  },
  data() {
    return {
      loading: false,
      tempChart: null,
      isChart: null,
      form: {},
      rules: {},
      allFileList: [],

      // 设备类型
      drawerDevice: false,
      deviceRadio: '',
      tableDeviceData: [],
      tableDeviceSelect: {},
      tableDeviceLoading: false,
      shapeOptions: [
        {
          value: 'realtime',
          label: '实时统计'
        }, 
        {
          value: 'total',
          label: '累计统计'
        },
        {
          value: 'trend',
          label: '趋势统计'
        },
        {
          value: 'contrast',
          label: '对比统计'
        },
        {
          value: 'merge-trend',
          label: '合并趋势统计'
        }
      ],
      selectedShapes: ''
    };
  },
  methods: {
    // 添加图表
    addChart() {
      this.deviceRadio = ''
      this.drawerDevice = true
    },
    //选择图表   
    selectChart(params) {
      this.tempChart = params 
    },
    drawerDeviceOK() {
      this.isChart = this.tempChart
      this.$emit('statisticsDimension',this.selectedShapes)
      this.$emit('graphCode',this.isChart)
      this.drawerDevice = false
    },  
    delChart() {
      this.tempChart = null
      this.isChart = null
    },
  },
  mounted() {
    
  },
};
</script>
  
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.form-box {
  padding: 0px 0px 0px;
  box-sizing: border-box;
}
.select-chart {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 114px 58px;
  cursor: pointer;
  user-select: none;
  margin: 10px;
  transition: all .3s ease;
}
.add-chart { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ccc;
  transition: all .3s ease;
  i {
    font-size: 30px;
  }
}
.select-chart:hover {
  border: 1px solid #1677ff;
}
.select-chart:hover .add-chart {
  color: #1677ff;
}
.drawer-bottom {
  z-index: 10;
  right: 35px;
}
/deep/.el-drawer__body {
  overflow: auto;
}
/deep/.el-tabs__item {
  width: 445px;
  text-align: center;
  font-size: 16px;
}
/deep/.el-tabs__active-bar {
  width: 445px;
}
.drawer-head {
  display: flex;
  align-items: center;
  padding: 10px 10px 0;
  .drawer-name {
    width: 70px;
    color: #797979;
    font-size: 14px;
    margin-right: 10px;
  }
}
.chart-size {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 40px;
}
.chart-flex {
  width: 100%;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  margin: 10px 0;
  background-color: #100C2A;
  .chart-title {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    margin-bottom: -1px;
    padding: 0 24px;
    font-weight: 600;
    font-size: 16px;
    // border-bottom: 1px solid #f0f0f0;
    border-bottom: 1px solid #100C2A;
    border-radius: 8px 8px 0 0;
    .l-title {
      font-weight: normal;
      font-size: 14px;
      display: inline-block;
      height: 20px;
      line-height: 19px;
      margin-right: 15px;
    }
    .r-btn {
      color: #0abde3;
    }
  }
}
.chart-empty {
  width: 100%;
  text-align: center;
  line-height: 600px;
  font-size: 14px;
  color: #909399;
}
/deep/.el-tag {
  font-weight: 400;
  user-select: none;
}
/deep/.el-tag--dark.el-tag--success {
  background-color: rgba(99, 226, 183, 0.15);
  border-color: rgba(99, 226, 183, 0.15);
  color: #63e2b7;
}
</style>